import { Component, OnInit, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Product } from '../../models/product.model';
import { Category, CategoryData } from '../../models/category.model';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { ProductState } from '../../state/product.state';
import { CategoryState } from '../../state/category.state';
import { ShowFirst, ShowMore, Searching, RemoveSearchingParam } from '../../state/product.action';
import { LoadCategory, SetCurrentCategory, RemoveCurrentCategory } from '../../state/category.action';
import { TdLoadingService } from '@covalent/core/loading';
import { loadContent, loading } from 'src/app/app.animations';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  animations: [
    loadContent,
    loading]
})
export class ListComponent implements OnInit {
  param: string;
  overlayStarSyntax = false;
  countItem;
  isOpen = false;
  @Output() item;
  @Select(ProductState.getShowMoreDisplay) next$: Observable<any>; // todo type
  @Select(ProductState.getProductList) products$: Observable<Product[]>;
  @Select(CategoryState.getCategories) categories$: Observable<any>;
  @Select(CategoryState.getPathDataById) breadscrumbsData$: Observable<any>;
  @Select(state => state.category.categoryDict) categoryDict$: Observable<CategoryData>;
  @Select(state => state.category.breadscrumbs) breadscrumbs$: Observable<number[]>;
  @Select(state => state.category.currentCategory) currentCategory$: Observable<number>;
  @Select(state => state.product.length) length$: Observable<number>;
  @Select(ProductState.getCountByItemKey) count$: Observable<number>;
  @Select(state => state.product.selectedPage) page$: Observable<number>;
  @Select(state => state.product.selectedSearch) searchParam$: Observable<number>;


  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private loadingService: TdLoadingService) {
    }
  private subscriptions = new Subscription();

  ngOnInit() {
    this.loadingService.register('overlayStarSyntax');
    this.subscriptions.add(
      this.route.params.subscribe(
        params => {
          const param = params.nameCatWithID;
          if (param) {
            this.loadingService.register('overlayStarSyntax');
            const id = params.nameCatWithID.split('_')[1];
            this.store.dispatch(new SetCurrentCategory(id));
          } else {
            this.loadingService.register('overlayStarSyntax');
            this.store.dispatch(new RemoveCurrentCategory());
          }
        }
      )
    );
    this.subscriptions.add(
      this.route.queryParams
        .subscribe(params => {
          if (params[0]) {
            if (this.countItem !== 0) {
              this.loadingService.register('overlayStarSyntax');
            }
            this.store.dispatch(new Searching(params[0]));
          } else {
            this.loadingService.register('overlayStarSyntax');
            this.store.dispatch([
              new RemoveSearchingParam(),
              new ShowFirst()
            ]);
          }
        })
    );
    this.store.dispatch(new LoadCategory());
    this.subscriptions.add(
      this.products$.subscribe(x => {
        if (x && x.length) {
          this.loadingService.resolve('overlayStarSyntax');
        }
      })
    );
    this.subscriptions.add(
      this.count$.subscribe(x => {
        this.countItem = x;
        if (x < 1) {
          this.loadingService.resolve('overlayStarSyntax');
        }
      })
    );
  }
  choiseBreadscrum(data) {
    this.store.dispatch([
      new SetCurrentCategory(data.id),
      new Navigate(['/catalogue/category/', `${data.slug}_${data.id}`])
    ]);
  }
  showMore() {
    this.store.dispatch(
      new ShowMore(),
    );
  }

  productId(_, item) {
    return item.id;
  }
}
