import { NgxsOnInit, StateContext, State, Store, Selector, Action } from '@ngxs/store';
import { Basket } from './models/basket';
import {
  AddToBasket, BasketLoadProduct,
  BasketUpdateProductQuantity, BasketLoadProducts,
  SetCurrentProduct, LoadRecommended, LoadRecommendedSuccess, ClearingRecommended
} from './basket.actions';
import { LoadProperty } from '../catalog/container/details/details.actions';
import { trimEnd, endsWith, forEach, has, } from 'lodash';
import { Navigate } from '@ngxs/router-plugin';
import { ProductState, ProductStateModel } from '../catalog/state/product.state';
import { BackendError } from '../messages/messages.actions';
import { BasketService } from './service/basket.service';
import { Product } from '../catalog/models/product.model';

export class BasketStateModel {
  data: {
    [id: number]: Basket,
  };
  selectedProduct: number;
  recommended: Product[];
}

@State<BasketStateModel>({
  name: 'basket',
  defaults: {
    data: {},
    selectedProduct: null,
    recommended: []
  }
})
export class BasketState implements NgxsOnInit {
  constructor(
    private store: Store,
    private basketService: BasketService
  ) { }

  @Selector()
  static getBasketItemsIds(state: BasketStateModel) {
    return Object.keys(state.data).map(id => parseInt(id, 10));
  }

  @Selector([ProductState])
  static getData(state: BasketStateModel, productState: ProductStateModel) {
    if (has(productState, `dict.${state.selectedProduct}`) && (has(state, `data.${state.selectedProduct}`))) {
      return { ...productState.dict[state.selectedProduct], ...state.data[state.selectedProduct] };
    }
  }
  @Selector([ProductState])
  static getBasketData(state: BasketStateModel, productState: ProductStateModel) {
    const result = [];

    if (state && state.data) {
      forEach(state.data, (el) => {
        let item: any;
        if (has(productState, `dict.${el.id}`)) {
          item = Object.assign(productState.dict[el.id], el);
        } else {
          item = { ...el };
        }
        result.push(item);
      });
    }

    return result;
  }

  @Selector([ProductState])
  static getBasketTotalPrice(state: BasketStateModel, productState: ProductStateModel) {
    const data = BasketState.getBasketData(state, productState);
    return data.filter(el => has(el, `price_data.excl_tax`))
      .reduce((acc, el) => acc += el.quantity * el.price_data.excl_tax, 0);
  }

  @Action(AddToBasket)
  onAddToBasket(ctx: StateContext<BasketStateModel>, { id, quantity }: AddToBasket) {
    const state = ctx.getState();
    quantity = Math.floor(quantity);

    if (quantity >= 1) {
      if (state && state.data && state.data[id]) {
        quantity += state.data[id].quantity;
      }

      const newItem = { [id]: { id, quantity } as Basket };
      ctx.patchState({
        data: { ...state.data, ...newItem }
      });
      this.store.dispatch(new Navigate(['/basket/add', id]));
    }
  }

  @Action(BasketLoadProduct)
  onBasketLoadProduct(ctx: StateContext<BasketStateModel>, { id }: BasketLoadProduct) {
    const fields = ['title', 'availability_data', 'price_data'];

    fields.forEach(prop => {
      if (endsWith(prop, '_data')) {
        this.store.dispatch(new LoadProperty(id, trimEnd(prop, '_data')));
      }
    });
  }

  @Action(BasketLoadProducts)
  onBasketLoadProducts(ctx: StateContext<BasketStateModel>, { ids, }: BasketLoadProducts) {
    ids.forEach(id => this.store.dispatch(new BasketLoadProduct(id)));
  }

  ngxsOnInit(ctx: StateContext<BasketStateModel>) { }

  @Action(BasketUpdateProductQuantity)
  onBasketUpdateProductQuantity(ctx: StateContext<BasketStateModel>, { id, q }: BasketUpdateProductQuantity) {
    const state = ctx.getState();
    if (q === 0) {
      const { [id]: _, ...newState } = state.data || {};

      ctx.patchState({
        data: newState
      });
      return;
    }

    const newItem = { [id]: { id, quantity: q } as Basket };
    ctx.patchState({
      data: { ...state.data, ...newItem }
    });
  }
  @Action(SetCurrentProduct)
  onSetCurrentProduct(ctx: StateContext<BasketStateModel>, { id }: SetCurrentProduct) {
    ctx.patchState({
      selectedProduct: id
    });
  }
  @Action(LoadRecommended)
  onLoadRecommended(ctx: StateContext<BasketStateModel>) {
    const state = ctx.getState();
    this.basketService.getRtProductProperty(state.selectedProduct, 'recommended').subscribe(
      data => ctx.dispatch(new LoadRecommendedSuccess(data)),
      err => ctx.dispatch(new BackendError(err)),
    );

  }
  @Action(LoadRecommendedSuccess)
  onLoadRecommendedSuccess(ctx: StateContext<BasketStateModel>, { data }: LoadRecommendedSuccess) {
    ctx.patchState({
      recommended: data
    });
  }
  @Action(ClearingRecommended)
  onClearingRecommended(ctx: StateContext<BasketStateModel>) {
    ctx.patchState({
      recommended: []
    });
  }
}
