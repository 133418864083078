import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/catalog/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  constructor(private http: HttpClient) { }
  private apiUrl = '/api';

  getProductItem(id: number) {
    const url = `${this.apiUrl}/products/${id}`;
    return this.http.get(url);
  }

  getProductProperty(id: number, property: string) {
    const url = `${this.apiUrl}/products/${id}/${property}`;
    return this.http.get(url);
  }

  getRtProductProperty(id: number, property: string): Observable<Product[]> {
    const url = `${this.apiUrl}/rt-oscar/products/${id}/${property}`;
    return this.http.get<Product[]>(url);
  }

}
