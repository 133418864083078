
import {Injectable, Inject, EventEmitter, ElementRef} from '@angular/core';

import {DOCUMENT} from '@angular/common';
import {PageScrollService, PageScrollInstance, PageScrollConfig} from 'ngx-page-scroll';
import {Subject} from 'rxjs';
import {PageScrollOptions} from 'ngx-page-scroll/src/ngx-page-scroll-instance';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  protected currentElementSource = new Subject();
  currentElement$ = this.currentElementSource.asObservable();
  localCurrentElement = '';
  private defaultOffset = 60;
  idsMap: string[] = [];
  public moveToElement: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: Document,

  ) {
    PageScrollConfig.defaultDuration = 200;
  }


  onScroll() {
    for (const id of this.idsMap) {
      const el = document.getElementById(id);
      if (el) {
        const top = el.getBoundingClientRect().top;
        const bottom = el.getBoundingClientRect().top + el.offsetHeight;
        if (top <= 150 && bottom > 100) {
          if (id && id !== this.localCurrentElement) {
            this.setCurrentElement(id);
          }
        }
      }
    }
  }

  /**
   * Scroll the page to the element
   * @param id;
   * @param accurat: {boolean} ; If accuracy is needed we are making the second attempt to achieve the id
   */
  toId(id: string, accurat: boolean = true) {
    if (!id) {
      return false;
    }
    const options = {
      document: this.document,
      scrollTarget: '#' + id,
      pageScrollOffset: this.defaultOffset,
      verticalScrolling: true,
      advancedInlineOffsetCalculation: true,
      pageScrollFinishListener: new EventEmitter<boolean>()
    };

    if (accurat) {
      const finished = new EventEmitter<boolean>();
      finished.subscribe(() => this.toId(id, false));
      options.pageScrollFinishListener = finished;
    }

    const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance(options);
    // Hack because page sometimes doesn't want to scroll directly
    setTimeout(() => {
      this.pageScrollService.start(pageScrollInstance);
    });
  }

  toClass(className, additionalOptions?) {
    let options: PageScrollOptions = {
      document: this.document,
      scrollTarget: '.' + className,
      pageScrollOffset: 100 + this.defaultOffset,
      verticalScrolling: true
    };

    if (additionalOptions) {
      options = {...options, ...additionalOptions};
    }

    const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance(options);


    setTimeout(() => {
      this.pageScrollService.start(pageScrollInstance);
    });
  }

  toIdInContainer(id: string, containerId: string, defOffset?: number, scrollTarget?) {
    const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance({
      document: this.document,
      scrollTarget: scrollTarget ? scrollTarget : '#' + id,
      scrollingViews: [this.document.getElementById(containerId)],
      verticalScrolling: true,
      advancedInlineOffsetCalculation: true,
      pageScrollOffset: defOffset
    });
    setTimeout(() => {
      this.pageScrollService.start(pageScrollInstance);
    }, 300);
  }

  /**
   * Scroll the parent element until child element become visible (if they exist)
   * @param childId;
   * @param parentId;
   * @returns true, if parent element is not exists, null if child element is not exists, otherwise false;
   */
  scrollIfNotVisible(childId: string, parentId: string): boolean {
    const childElement = this.document.getElementById(childId);
    const parentElement = this.document.getElementById(parentId);

    if (!parentElement) {
      return true;
    }

    if (!childElement) {
      return null;
    }
    if (childElement.getBoundingClientRect().top > parentElement.offsetHeight) {
      this.toIdInContainer(childId, parentId);
    }
    return false;
  }

  scrollHierarchy(childId: string, parentId: string): boolean {
    const childElement = this.document.getElementById(childId);
    const parentElement = this.document.getElementById(parentId);
    if (!parentElement) {
      return true;
    }

    if (!childElement) {
      return null;
    }
    const top = childElement.getBoundingClientRect().top;
    if (top < parentElement.getBoundingClientRect().top || top > parentElement.getBoundingClientRect().bottom) {
      this.toIdInContainer(childId, parentId, this.defaultOffset, childElement);
    }
    return false;
  }

  setCurrentElement(id: string) {
    this.localCurrentElement = id;
    this.currentElementSource.next(id);
  }
}
