import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketMiniComponent } from './components/basket-mini/basket-mini.component';
import { BasketComponent } from './container/basket/basket.component';
import { BasketState } from './basket.state';
import { NgxsModule } from '@ngxs/store';
import { RouterModule } from '@angular/router';
import { AddComponent } from './container/add/add.component';
import { ButtonAddComponent } from './container/button-add/button-add.component';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { Settings } from 'src/conf/settings';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([
      BasketState,
    ]),
    NgxsRouterPluginModule.forRoot(),
    RouterModule,
  ],
  exports: [
    BasketMiniComponent,
    ButtonAddComponent,
  ],
  declarations: [BasketMiniComponent, BasketComponent, AddComponent, ButtonAddComponent, ],
  providers: [ Settings ],
})
export class BasketModule { }
