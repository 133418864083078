import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { Routes, RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { AuthService } from './auth.service';
import { AuthState } from './auth.state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './auth.guard';
import { RtFormsModule } from '../rt-forms/rt-forms.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { HttpClientModule } from '@angular/common/http';
import { PasswordResetConfirmComponent } from './password-reset-confirm/password-reset-confirm.component';
import { ProfileComponent } from '../accounts/profile/profile.component';
import { EditprofileComponent } from '../accounts/editprofile/editprofile.component';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { UserService } from '../services/user.service';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { NotVerifiedComponent } from './not-verified/not-verified.component';
import { VerifiedGuard } from './guards';

export const AUTH_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {loginRoute: true},
  },
  {
    path: 'register',
    component: RegistrationComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent
  },
  {
    path: 'password/reset/confirm/:uid/:token',
    component: PasswordResetConfirmComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'confirm-email/:key/',
    component: ConfirmEmailComponent,
  },
  {
    path: 'not-verified',
    component: NotVerifiedComponent
  }
];

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule.forChild(AUTH_ROUTES),
    NgxsModule.forFeature([
      AuthState
    ]),
    FormsModule,
    ReactiveFormsModule,
    RtFormsModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    AuthComponent,
    LogoutComponent,
    LoginComponent,
    RegistrationComponent,
    PasswordResetComponent,
    PasswordResetConfirmComponent,
    ConfirmEmailComponent,
    NotVerifiedComponent,
  ],
  providers: [

    AuthService,
    UserService,
    AuthGuard,
    VerifiedGuard
  ],
  exports: [
    AuthComponent,
  ],
})
export class AuthModule { }
