import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../../auth';
import { Observable } from 'rxjs';
import { RemoveCurrentCategory } from 'src/app/catalog/state/category.action';
import { ShowFirst } from 'src/app/catalog/state/product.action';
import { loadStatic } from 'src/app/app.animations';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    loadStatic
  ]
})
export class NavbarComponent implements OnInit {

  constructor(private store: Store) { }

  @Select (AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

  ngOnInit() {}

  destroyCurrentCat() {
    this.store.dispatch([
      new RemoveCurrentCategory(),
      new ShowFirst()
    ]
    );
  }

}
