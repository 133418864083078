import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {FormFields, ValidationMessages} from '../../rt-forms/models';
import {FormBuilder, Validators} from '@angular/forms';
import {Init, ResetPasswordConfirm} from '../auth.actions';
import {Token} from '../auth.model';
import {MessagesState} from '../../messages/messages.state';
import {NgxsSingleFormComponent} from '../../rt-forms/ngxs-single-form-component';
import {Observable} from 'rxjs';
import {AuthState} from '../auth.state';

@Component({
  selector: 'app-password-reset-confirm',
  templateUrl: './password-reset-confirm.component.html',
  styleUrls: ['./password-reset-confirm.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetConfirmComponent extends NgxsSingleFormComponent implements OnInit, OnDestroy {
  @Select(AuthState.isLoading) loading$: Observable<boolean>;
  @Select(MessagesState.getBackendError) backendErrors$: Observable<any>;
  protected deferredValidation = true;

  public labels = {
    password1: 'New Password',
    password2: 'New Password (again)',
  };
  public formFields: FormFields = {
    new_password1: [Validators.required, Validators.maxLength(200)],
    new_password2: [Validators.required, Validators.maxLength(200)],
  };
  validationMessages: ValidationMessages = {
    new_password1: {
      required: 'Password is required',
      maxlength: 'Password should not be more than 200 characters',
    },
    new_password2: {
      required: 'Confirm password is required',
      maxlength: 'Password should not be more than 200 characters',
    },
  };

  uid: string;
  token: Token;

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    protected store: Store,
    private route: ActivatedRoute,
  ) {
    super(fb, cd, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.dispatch(new Init());
    this.subscription.add(
      this.route.params.subscribe(
        params => {
          this.uid = params.uid;
          this.token = params.token;
        }
      )
    );
  }

  protected performSubmit() {
    this.store.dispatch(new ResetPasswordConfirm(this.form.value, this.uid, this.token));
  }

}
