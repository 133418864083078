import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationService} from './validation.service';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {InputBlockComponent} from './input-block/input-block.component';
import {InputBlockSelectComponent} from './input-block-select/input-block-select.component';
import {InputBlockTextareaComponent} from './input-block-textarea/input-block-textarea.component';
import { InputBlockCheckboxComponent } from './input-block-checkbox/input-block-checkbox.component';
import {ResponseProcessorService} from './response-processor.service';
import { MatInputModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatButtonModule } from '@angular/material';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { SearchBlockComponent } from './search-block/search-block.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPageScrollModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatButtonModule

  ],
  exports: [
    InputBlockComponent,
    InputBlockSelectComponent,
    InputBlockTextareaComponent,
    InputBlockCheckboxComponent,
    StarRatingComponent,
    SearchBlockComponent
  ],
  declarations: [
    InputBlockComponent,
    InputBlockSelectComponent,
    InputBlockTextareaComponent,
    InputBlockCheckboxComponent,
    StarRatingComponent,
    SearchBlockComponent
  ],
  providers: [
    ValidationService,
    ResponseProcessorService
  ]
})
export class RtFormsModule { }
