import { Product } from '../catalog/models/product.model';

export class AddToBasket {
    static type = '[Basket] add new item';
    constructor(public id: number, public quantity: number) {}
}

export class BasketLoadProduct {
    static type = '[Basket] BasketLoadProduct';
    constructor(public id: number) {}
}

export class BasketLoadProducts {
    static type = '[Basket] BasketLoadProducts';
    constructor(public ids: number[], ) {}
}

export class BasketLoadProductProperty {
    static type = '[Basket] BasketLoadProductProperty';
    constructor(public id: number, public property: string) {}
}

export class BasketUpdateProductQuantity {
    static type = '[Basket] BasketUpdateProductQuantity';
    constructor(public id: number, public q: number) {}
}

export class BasketUpdateProductTotal {
    static type = '[Basket] BasketUpdateProductTotal';
    constructor(public id: number, public total: number, ) {}
}

export class SetCurrentProduct {
    static type = '[Basket] SetCurrentProduct';
    constructor(public id: number) {}
}

export class LoadRecommended {
    static type = '[Basket] LoadRecommended';
}
export class LoadRecommendedSuccess {
    static type = '[Basket] LoadRecommendedSuccess';
    constructor(public data: Product[]) {}
}
export class  ClearingRecommended {
    static type = '[Basket]  ClearingRecommended';
}

