import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { AddToBasket } from '../../basket.actions';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';

@Component({
  selector: 'app-button-add',
  templateUrl: './button-add.component.html',
  styleUrls: ['./button-add.component.css']
})
export class ButtonAddComponent implements OnInit {

  constructor(
    private store: Store,
    ) { }

  @Input() productId: number;

  ngOnInit() {}

  addToBasket(quantity = 1) {
    this.store.dispatch(new AddToBasket(this.productId, quantity));
  }
}
