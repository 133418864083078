import {Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {Init, LoginWithEmailAndPassword} from '../auth.actions';
import {FormBuilder, Validators} from '@angular/forms';
import {FormFields, ValidationMessages} from '../../rt-forms/models';
import {AuthState} from '../auth.state';
import {Observable} from 'rxjs';
import { MessagesState } from '../../messages/messages.state';
import {NgxsSingleFormComponent} from '../../rt-forms/ngxs-single-form-component';

import { loadContent } from 'src/app/app.animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    loadContent
  ]
})
export class LoginComponent extends NgxsSingleFormComponent implements OnInit, OnDestroy {

  public formFields: FormFields = {
    email: [Validators.required, Validators.email, Validators.maxLength(200)],
    password: [Validators.required, Validators.maxLength(200)],
  };
  validationMessages: ValidationMessages = {
    email: {
      required: 'Email is required',
      email: 'Email is not valid',
      maxlength: 'Email should not be more than 200 characters',
    },
    password: {
      required: 'Password is required',
      maxlength: 'Password should not be more than 200 characters',
    },
  };
  formLabels = {
    email: 'Email',
    password: 'Password',
  };
  @Select(AuthState.isLoading) loading$: Observable<boolean>;
  @Select(MessagesState.getBackendError) backendErrors$: Observable<any>;

  constructor(
    protected store: Store,
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    ) {
    super(fb, cd, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.dispatch(new Init());
  }

  protected initObject() {
    // Do not need initial object
  }

  protected performSubmit() {
    this.store.dispatch(new LoginWithEmailAndPassword(this.form.value));
  }
}
