import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basket-mini',
  templateUrl: './basket-mini.component.html',
  styleUrls: ['./basket-mini.component.css']
})
export class BasketMiniComponent implements OnInit {
  constructor() { }

  public opened = false;

  ngOnInit() {
  }

}
