import { Component, OnInit, Input } from '@angular/core';
import { Product, ProductListResponse } from '../../models/product.model';
import { Settings } from 'src/conf/settings';
import { loadImage } from 'src/app/app.animations';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  animations: [loadImage]
})
export class ProductComponent implements OnInit {
  rating: number;
  // imageLoaded = false;
  @Input() item: Product;

  constructor(
    public settings: Settings,
    ) { }

  ngOnInit() {
    this.rating = this.item.rating;
  }

  // show(e) {
  //   this.imageLoaded = true;
  // }

}
