import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormFields, ValidationMessages} from '../../rt-forms/models';
import {FormBuilder, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {Init, ResetPassword} from '../auth.actions';
import {Message, MessagesState} from '../../messages/messages.state';
import {NgxsSingleFormComponent} from '../../rt-forms/ngxs-single-form-component';
import {AuthState} from '../auth.state';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetComponent extends NgxsSingleFormComponent implements OnInit, OnDestroy {

  @Select(AuthState.isLoading) loading$: Observable<boolean>;
  @Select(AuthState.isLoaded) loaded$: Observable<boolean>;
  @Select(MessagesState.getBackendError) backendErrors$: Observable<any>;
  @Select(MessagesState.getSuccessMessages) successMessages$: Observable<Message[]>;

  protected deferredValidation = true;

  public labels = {
    email: 'Email'
  };

  public formFields: FormFields = {
    email: [Validators.required, Validators.email, Validators.maxLength(200)],
  };

  validationMessages: ValidationMessages = {
    email: {
      required: 'Email is required',
      email: 'Email is not valid',
      maxlength: 'Email should not be more than 200 characters',
    },
  };

  constructor(
    protected fb: FormBuilder,
    protected cd: ChangeDetectorRef,
    protected store: Store,
  ) {
    super(fb, cd, store);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.dispatch(new Init());
  }

  protected performSubmit() {
    this.store.dispatch(new ResetPassword(this.form.value));
  }

}
