import { Component, OnInit, OnDestroy, EventEmitter, Output,
  HostBinding, OnChanges, ViewChild, ElementRef,
  ChangeDetectorRef, DoCheck, AfterViewChecked, AfterContentChecked, SimpleChanges } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BasketState } from '../../basket.state';
import { BasketUpdateProductQuantity, BasketLoadProducts } from '../../basket.actions';
import { Product } from 'src/app/catalog/models/product.model';
import { isEqual } from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { settings } from 'src/environments/environment';
import { loadContent, dropItem } from 'src/app/app.animations';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css'],
  animations: [
    loadContent,
    dropItem,
  ]
})
export class BasketComponent implements OnInit, OnDestroy {
  mustBeResize = false;
  startHeight: number;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
  ) { }

  @ViewChild('basketItems') basketItems: ElementRef;

  private subscriptions = new Subscription();
  @Select(BasketState.getBasketItemsIds) idList$: Observable<number[]>;
  @Select(BasketState.getBasketData) basket$: Observable<any>;
  @Select(BasketState.getBasketTotalPrice) total$: Observable<number>;

  ngOnInit() {
    this.cd.detectChanges();
    this.subscriptions.add(
      this.idList$
        .pipe(distinctUntilChanged(isEqual))
        .subscribe(ids => this.store.dispatch(new BasketLoadProducts(ids)))
    );
  }

  updateQuantity(item, q: number) {
    this.store.dispatch(new BasketUpdateProductQuantity(item.id, q));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  removeItem(id: number) {
    this.store.dispatch(new BasketUpdateProductQuantity(id, 0));
  }

  calculatePrice(item) {
    if (settings.isNumber(item.quantity) && settings.isNumber(item.price_data.excl_tax)) {
      return item.quantity * item.price_data.excl_tax;
    }
  }
}
